import * as React from 'react';
import self from '../../img/photographs/mammoth.jpg';
import * as st from '../../components/styled-component-definitions';
import SiteFooter from './sitefooter';
import SiteHeader from './siteheader';

interface ContactState {}

interface ContactProps {}

export default class Contact extends React.Component<
	ContactProps,
	ContactState
> {
	render() {
		return (
			<st.FullHeightVerticalContainer>
				<SiteHeader />

				<st.VeryVerySmallPaddedSection />
				<div className="columns is-centered">
					<div className="column is-4">
						<figure className="image">
							<img src={self} alt="Highballin'"></img>
						</figure>
					</div>
				</div>
				<div className="columns is-centered">
					<div className="column is-4">
						<st.VeryVerySmallPaddedSection />

						<p>
							{' '}
							Please use the form below to inquire about purchases or
							commissions.{' '}
						</p>

						<st.VerySmallPaddedSection />

						<form
							action="https://getform.io/f/f07435a3-7cc4-4dfa-a6f6-407c81697a01"
							method="POST"
						>
							<div className="field">
								<label className="label has-text-kcdarkgray">Name</label>
								<div className="control">
									<input
										className="input"
										type="name"
										name="name"
										placeholder="e.g. Jane Doe"
									/>
								</div>
							</div>
							<div className="field">
								<label className="label has-text-kcdarkgray">Email</label>
								<div className="control">
									<input
										className="input"
										type="email"
										placeholder="e.g. hello@example.com"
										name="email"
									/>
								</div>
							</div>
							<div className="field">
								<label className="label has-text-kcdarkgray">Message</label>
								<div className="control">
									<textarea
										className="textarea"
										placeholder="Write a message..."
										name="message"
									></textarea>
								</div>
							</div>

							<st.VerySmallPaddedSection />

							<div className="field">
								<div className="control has-text-centered">
									<button
										className="button is-dark has-text-kcdarkgray darkgrayiconcolor"
										type="submit"
									>
										<i className="fa fa-paper-plane" /> &ensp; Send
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>

				<st.PaddedSection />

				<SiteFooter />
			</st.FullHeightVerticalContainer>
		);
	}
}
