import * as React from 'react';
import * as st from '../../components/styled-component-definitions';

interface SiteFooterState {}

interface SiteFooterProps {}

export default class SiteFooter extends React.Component<
  SiteFooterProps,
  SiteFooterState
> {
  render() {
    return (
      <st.StyledFooter className="footer">
        <st.SmallPaddedSection />

        <div className="container level-item has-text-centered is-4 is-offset-4 is-size-6">
          &#169; 2021 Korok Chatterjee &ensp; &ensp;
          <a href="https://www.instagram.com/footb1rd/">
            <i
              className="fab fa-instagram darkgrayiconcolor"
              aria-hidden="true"
            />
          </a>{' '}
          &ensp; &thinsp;
          <a href="mailto:korokc@gmail.com">
            <i
              className="fas fa-envelope darkgrayiconcolor"
              aria-hidden="true"
            />
          </a>
        </div>
      </st.StyledFooter>
    );
  }
}
