import * as React from 'react';
import brionescow from '../../../img/photographs/briones-cow.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface Photograph28State {}

interface Photograph28Props {}

export default class Photograph28 extends React.Component<
  Photograph28Props,
  Photograph28State
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <st.Columns className="columns">
          <st.MainImageContainer className="column is-6 is-offset-3">
            <div className="level">
              <a href="/photographs/photograph27">
                <figure className="image">
                  <img src={brionescow} alt="cow at briones"></img>
                </figure>
              </a>
            </div>
            <div className="level has-text-left">
              <div className="content is-size-7 has-text-centered-mobile">
                COW AT BRIONES <br /> <i>2018</i> <br /> Briones Regional Park,
                CA, USA.
                <br /> 35mm Film. <i> Prints Available. </i>
              </div>
            </div>
          </st.MainImageContainer>
        </st.Columns>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/photographs/photograph29">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/photographs/photograph27">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/photographs1" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
