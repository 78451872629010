import * as React from 'react';
import shastasummit from '../../../img/photographs/shastasummit.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface Photograph1State {}

interface Photograph1Props {}

export default class Photograph1 extends React.Component<
  Photograph1Props,
  Photograph1State
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <st.Columns className="columns">
          <st.MainImageContainer className="column is-6 is-offset-3">
            <div className="level">
              <a href="/photographs/photograph33">
                <figure className="image">
                  <img
                    src={shastasummit}
                    alt="summit block of mt. shasta"
                  ></img>
                </figure>
              </a>
            </div>
            <div className="level has-text-left">
              <div className="content is-size-7 has-text-centered-mobile">
                SUMMIT BLOCK OF MT. SHASTA <br /> <i>2018</i> <br />{' '}
                Shasta-Trinity National Forest, CA, USA. <br /> 35mm Film.{' '}
                <i> Prints Available. </i>
              </div>
            </div>
          </st.MainImageContainer>
        </st.Columns>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/photographs/photograph2">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/photographs/photograph33">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/photographs2" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
