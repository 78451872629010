import * as React from 'react';
import architecture from '../../../img/drawings/architecture.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface ArchitectureState {}

interface ArchitectureProps {}

export default class Architecture extends React.Component<
  ArchitectureProps,
  ArchitectureState
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <st.VerySmallPaddedSection />

        <st.Columns className="columns is-centered">
          <st.MainImageContainer className="column is-4">
            <div className="level">
              <a href="/drawings/whitehorse">
                <figure className="image">
                  <img src={architecture} alt="rectangular forms"></img>
                </figure>
              </a>
            </div>
            <div className="level has-text-left">
              <div className="content is-size-7 has-text-centered-mobile">
                RECTANGULAR FORMS <br /> <i>2021</i> <br /> Graphite on Paper.
                10.5 x 7.25 in. <i> Available. </i>
              </div>
            </div>
          </st.MainImageContainer>
        </st.Columns>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/drawings/oilrig">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/drawings/whitehorse">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/drawings1" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
