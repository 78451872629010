import * as React from 'react';
import card2 from '../../../img/paintings/card2.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface Card2State {}

interface Card2Props {}

export default class Card2 extends React.Component<Card2Props, Card2State> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <st.VerySmallPaddedSection />

        <st.Columns className="columns is-centered">
          <st.MainImageContainer className="column is-4">
            <div className="level">
              <a href="/paintings/harbor">
                <figure className="image">
                  <img src={card2} alt="los angeles landscape 2"></img>
                </figure>
              </a>
            </div>
            <div className="level has-text-left">
              <div className="content is-size-7 has-text-centered-mobile">
                LOS ANGELES LANDSCAPE 2 <br /> <i>2019</i> <br /> Watercolor and
                Ink on Paper. 6 x 4.5 in. <i> Sold. </i>
              </div>
            </div>
          </st.MainImageContainer>
        </st.Columns>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/paintings/card1">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/paintings/harbor">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/paintings" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
