import * as React from 'react';
import toothface from '../../../img/drawings/toothface-new-crop.jpg';
import sowmz from '../../../img/drawings/sowmz-new-crop.jpg';
import batman from '../../../img/drawings/batman-crop.jpg';
import vaporhead from '../../../img/drawings/vaporhead-crop.jpg';
import organism from '../../../img/drawings/organism-new-crop.jpg';
import icthyology from '../../../img/drawings/icthyology-new-crop.jpg';
import preggo from '../../../img/drawings/preggo-crop.jpg';
import birdhead from '../../../img/drawings/bird-head-new-crop.jpg';
import horse from '../../../img/drawings/horse-crop.jpg';
import carlene from '../../../img/drawings/carlene-crop.jpg';
import armhead from '../../../img/drawings/arm-head-new-crop.jpg';
import hummingbird from '../../../img/drawings/hummingbird-crop.jpg';
import cyborg from '../../../img/drawings/cyborg-crop.jpg';
import twoheads from '../../../img/drawings/two-heads-new-crop.jpg';
import birdman from '../../../img/drawings/birdman-crop.jpg';
import warriors from '../../../img/drawings/warriors-new-crop.jpg';
import earface from '../../../img/drawings/earface-new-crop.jpg';
import leviathan from '../../../img/drawings/leviathan-crop.jpg';
import putrefaction from '../../../img/drawings/putrefaction-crop.jpg';
import eustace from '../../../img/drawings/eustace-new-crop.jpg';
import greensun from '../../../img/drawings/green-sun-crop.jpg';
import greencircle from '../../../img/drawings/portrait-green-crop.jpg';
import muybridge from '../../../img/drawings/muybridge-crop.jpg';
import predator from '../../../img/drawings/predator-crop.jpg';
import lampshade from '../../../img/drawings/lampshade-new-crop.jpg';
import vase from '../../../img/drawings/vase-new-crop.jpg';
import guitar from '../../../img/drawings/sowmz-guitar-fixed-crop.jpg';
import detritus1 from '../../../img/drawings/detritus1-new-crop.jpg';
import detritus2 from '../../../img/drawings/detritus2-new-crop.jpg';
import serpent from '../../../img/drawings/il-serpente-crop.jpg';
import whitehorse from '../../../img/drawings/white-horse-crop.jpg';
import yellowbox from '../../../img/drawings/yellow-box-crop.jpg';
import architecture from '../../../img/drawings/architecture-crop.jpg';
import liveoak from '../../../img/drawings/live-oak-crop.jpg';
import oilrig from '../../../img/drawings/oil-rig-crop.jpg';
import sleighbells from '../../../img/drawings/sleigh-bells-crop.jpg';
import windows from '../../../img/drawings/windows-crop.jpg';
import selfportrait from '../../../img/drawings/self-portrait-crop.jpg';
import sutcliffe from '../../../img/drawings/sutcliffe-crop.jpg';
import homestar from '../../../img/drawings/homestar-new-crop.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface Drawings3State {}

interface Drawings3Props {}

export default class Drawings3 extends React.Component<
  Drawings3Props,
  Drawings3State
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <div className="columns is-mobile">
          <st.AltContent className="column is-centered has-text-centered">
            DRAWINGS
          </st.AltContent>
        </div>

        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="drawings/putrefaction">
                <figure className="image-holder wp-caption">
                  <img className="demo" src={armhead} alt="putrefaction"></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="drawings/greencircle">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={vase}
                    alt="man with chartreuse sun"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
        </div>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/drawings/drawing26">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/drawings/drawing24">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a
            href="/portfolio"
            className="darkgrayiconcolor has-text-kcdarkgray"
          >
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
