import * as React from 'react';
import card1 from '../../../img/paintings/card1-crop.jpg';
import card2 from '../../../img/paintings/card2-crop.jpg';
import paynesgrey from '../../../img/paintings/paynes-grey-crop.jpg';
import flowers1 from '../../../img/paintings/flowers1-crop.png';
import flowers2 from '../../../img/paintings/flowers2-crop.png';
import faces from '../../../img/paintings/faces.jpg';
import harbor from '../../../img/paintings/harbor-crop.jpg';
import buggy from '../../../img/paintings/buggy-crop.jpg';
import boh from '../../../img/paintings/boh-crop.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface PaintingsState {}

interface PaintingsProps {}

export default class Paintings extends React.Component<
  PaintingsProps,
  PaintingsState
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <div className="columns is-mobile">
          <st.AltContent className="column is-centered has-text-centered">
            PAINTINGS
          </st.AltContent>
        </div>

        <div className="columns is-mobile is-hidden-desktop">
          <st.AltContent className="column is-centered has-text-centered is-size-7">
            tap image to view full size
          </st.AltContent>
        </div>

        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="paintings/boh">
                <figure className="image-holder wp-caption">
                  <img className="demo" src={boh} alt="piper with stick"></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="paintings/buggy">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={buggy}
                    alt="chihuahua in repose"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="paintings/card1">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={card1}
                    alt="los angeles landscape 1"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>

            <div className="column is-one-quarter">
              <a href="paintings/card2">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={card2}
                    alt="los angeles landscape 2"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="paintings/harbor">
                <figure className="image-holder wp-caption">
                  <img className="demo" src={harbor} alt="harbor"></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="paintings/paynesgrey">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={paynesgrey}
                    alt="monochrome in payne's grey"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="paintings/flowers1">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={flowers1}
                    alt="orange flowers with blue triangle"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>

            <div className="column is-one-quarter">
              <a href="paintings/faces">
                <figure className="image-holder wp-caption">
                  <img className="demo" src={faces} alt="faces"></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
        </div>

        <st.SmallPaddedSection />

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/portfolio" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
