import * as React from 'react';
import cyborg from '../../../img/drawings/cyborg.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface Drawing1State {}

interface Drawing1Props {}

export default class Drawing1 extends React.Component<
  Drawing1Props,
  Drawing1State
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <st.VerySmallPaddedSection />

        <st.Columns className="columns is-centered">
          <st.MainImageContainer className="column is-6">
            <div className="level">
              <a href="/drawings/drawing28">
                <figure className="image">
                  <img
                    src={cyborg}
                    alt="figure with mouths instead of eyes"
                  ></img>
                </figure>
              </a>
            </div>
            <div className="level has-text-left">
              <div className="content is-size-7 has-text-centered-mobile">
                CYBERNETIC ORGANISM <br /> <i>2015</i> <br /> Graphite on Paper.
                5.25 x 8 in. <i> Available. </i>
              </div>
            </div>
          </st.MainImageContainer>
        </st.Columns>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/drawings/drawing2">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/drawings/drawing28">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/drawings" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
