import styled from 'styled-components';

export const FullHeightVerticalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	min-height: 100vh;
`;

export const Content = styled.div`
	flex: 1;
	font-size: 1.2rem !important;
`;

export const AltContent = styled.div`
	flex: 1;
	font-size: 1rem !important;
`;

export const Caption = styled.div`
	flex: 1;
	font-size: 0.8rem !important;
`;

export const Hover = styled.div`
	color: #000000;
	:hover {
		color: #ff0000;
		cursor: pointer;
	}
`;

export const Div = styled.div`
	font-size: 1.2rem !important;
`;

export const PaddedDiv = styled.div`
	margin: 0 1rem;
`;

export const FullHeightImage = styled.img`
	height: 100% !important;
`;

export const Menu = styled.aside`
  margin 0 1rem;
  border: 0px solid black;	
  `;

export const ListItem = styled.li`
	font-size: 1.2rem;
	font-weight: bold;
	border: 0px solid black;
	padding: 0.2rem !important;
`;
export const A = styled.a`
	font-size: 1.2rem;
	font-weight: bold;
	border: 0px solid black;
	padding: 0.1rem !important;
`;

export const Header = styled.div`
	flex: 1;
	border-bottom: 0px solid black;
	padding-top: 3.5rem !important;
	padding-bottom: 1rem !important;
	margin-bottom: 0rem !important;
	font-family: Sofia Pro, sans serif;
`;

export const PaddedSection = styled.section`
	padding-top: 4rem !important;
	padding-bottom: 2rem !important;
	flex: 1;
`;

export const SmallPaddedSection = styled.section`
	padding-top: 1rem !important;
	padding-bottom: 1rem !important;
	flex: 1;
`;

export const VerySmallPaddedSection = styled.section`
	padding-top: 0.5rem !important;
	padding-bottom: 0.5rem !important;
	flex: 1;
`;

export const VeryVerySmallPaddedSection = styled.section`
	padding-top: 0.2rem !important;
	padding-bottom: 0rem !important;
	flex: 1;
`;

export const PortfolioItem = styled.div`
	margin-right: 1.5rem !important;
	margin-left: 1.5rem !important;
`;

export const SmallTopPadding = styled.div`
	padding-top: 0.5rem !important;
`;

export const BoldPara = styled.p`
	font-weight: bold;
`;

export const Columns = styled.div`
	border: 0px solid black;
	padding-top: 0rem !important;
	padding: 0rem !important;
	margin: 0rem !important;
`;

export const Column = styled.div`
	border: 0px solid black;
	padding-bottom: 0rem !important;
`;

export const MainImageContainer = styled.div`
	border: 0px solid black;
	padding-top: 0rem !important;
	padding-left: 0rem !important;
	padding-right: 0rem !important;
	padding-bottom: 0rem !important;
`;

export const StyledFooter = styled.footer`
	padding-top: 1rem !important;
	padding-bottom: 2rem !important;
	background: #111111 !important;
`;
