import * as React from 'react';
import * as st from '../../components/styled-component-definitions';

interface SiteHeaderState {}

interface SiteHeaderProps {}

export default class SiteHeader extends React.Component<
  SiteHeaderProps,
  SiteHeaderState
> {
  render() {
    return (
      <div>
        <st.Header className="level">
          <div className="level-item title has-ss-font has-text-centered is-1">
            <a href="/" className="has-text-kcdarkgray">
              Korok Chatterjee{' '}
            </a>
          </div>
        </st.Header>
        <div className="columns is-mobile">
          <st.Div className="column has-text-centered is-10 is-offset-1">
            <a href="/portfolio" className="has-text-kcdarkgray is-size-5">
              Portfolio &emsp;
            </a>
            <a href="/contact" className="has-text-kcdarkgray is-size-5">
              Contact
            </a>{' '}
          </st.Div>
        </div>
        <st.VerySmallPaddedSection />
      </div>
    );
  }
}
