import * as React from 'react';
import valley from '../../../img/photographs/valley-crop.jpg';
import yosemite from '../../../img/photographs/yosemite-crop.jpg';
import eastside from '../../../img/photographs/eastside-crop.jpg';
import buttermilks from '../../../img/photographs/buttermilks-crop.jpg';
import castlepeak from '../../../img/photographs/castlepeak-crop.jpg';
import biper from '../../../img/photographs/biper-crop.jpg';
import sowmz from '../../../img/photographs/sowmz-crop.jpg';
import briones from '../../../img/photographs/briones-crop.jpg';
import brionescow from '../../../img/photographs/briones-cow-crop.jpg';
import cropped from '../../../img/photographs/cropped-crop.jpg';
import mountainfog from '../../../img/photographs/mountain-fog-crop.jpg';
import piperbeach from '../../../img/photographs/piper-beach-crop.jpg';
import piperbed from '../../../img/photographs/piper-bed-crop.jpg';
import pipercastlerock from '../../../img/photographs/piper-castlerock-crop.jpg';
import piperchair from '../../../img/photographs/piper-chair-crop.jpg';
import piperfence from '../../../img/photographs/piper-fence-crop.jpg';
import pipergoat from '../../../img/photographs/piper-goat-crop.jpg';
import pipergrass from '../../../img/photographs/piper-grass-crop.jpg';
import piperindian from '../../../img/photographs/piper-indian-crop.jpg';
import pipertilden from '../../../img/photographs/piper-tilden-crop.jpg';
import shastasouthface from '../../../img/photographs/shastasouthface-crop.jpg';
import shastasummit from '../../../img/photographs/shastasummit-crop.jpg';
import sowmzbeach from '../../../img/photographs/sowmz-beach-crop.jpg';
import sowmzlamp from '../../../img/photographs/sowmz-lamp-crop.jpg';
import sowmzlop from '../../../img/photographs/sowmz-lop-crop.jpg';
import sowmzcastlerock from '../../../img/photographs/sowmz-castlerock-crop.jpg';
import sowmzwindow from '../../../img/photographs/sowmz-window-crop.jpg';
import treeaustin from '../../../img/photographs/tree-austin-crop.jpg';
import treemammoth from '../../../img/photographs/tree-mammoth-crop.jpg';
import treeshenry from '../../../img/photographs/trees-henry-crop.jpg';
import yard from '../../../img/photographs/yard-crop.jpg';
import yosemiteslab from '../../../img/photographs/yosemite-slab-crop.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface Photographs2State {}

interface Photographs2Props {}

export default class Photographs2 extends React.Component<
  Photographs2Props,
  Photographs2State
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <div className="columns is-mobile">
          <st.AltContent className="column is-centered has-text-centered">
            PHOTOGRAPHS
          </st.AltContent>
        </div>

        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph17">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={treeshenry}
                    alt="trees on henry street"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph16">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={cropped}
                    alt="cropped trees on henry street"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph15">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={sowmzwindow}
                    alt="sowmya by the window"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph14">
                <figure className="image-holder wp-caption">
                  <img className="demo" src={yard} alt="backyard"></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph13">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={treeaustin}
                    alt="tree at pace bend"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph12">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={sowmzbeach}
                    alt="sowmya at point reyes"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph11">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={piperbeach}
                    alt="piper at point reyes"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph10">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={treemammoth}
                    alt="tree at mammoth lakes"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph9">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={sowmzlamp}
                    alt="sowmya with brass lamp"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph8">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={piperbed}
                    alt="piper on the bed"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>

            <div className="column is-one-quarter">
              <a href="photographs/photograph6">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={piperfence}
                    alt="piper defending her territory"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>

            <div className="column is-one-quarter">
              <a href="photographs/photograph5">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={sowmzcastlerock}
                    alt="sowmya at skyline"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph4">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={pipercastlerock}
                    alt="piper at skyline"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph3">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={pipertilden}
                    alt="piper in tilden"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph2">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={shastasouthface}
                    alt="south face of mt. shasta"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>

            <div className="column is-one-quarter">
              <a href="photographs/photograph1">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={shastasummit}
                    alt="summit block of mt. shasta"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
        </div>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/photographs1">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/photographs1">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/portfolio" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
