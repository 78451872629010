import * as React from 'react';
import yosemite from '../../img/yosemite.jpg';
import sowmz from '../../img/drawings/sowmz.jpg';
import glory from '../../img/portfolio/glory2.jpg';
import batman from '../../img/drawings/batman.jpg';
import * as st from '../../components/styled-component-definitions';
import SiteFooter from './sitefooter';
import SiteHeader from './siteheader';

interface IndexState {}

interface IndexProps {}

export default class Index extends React.Component<IndexProps, IndexState> {
	render() {
		return (
			<st.FullHeightVerticalContainer>
				<SiteHeader />

				<st.Columns className="columns is-centered">
					<st.MainImageContainer className="column is-7">
						<a href="/portfolio">
							<figure className="image">
								<img src={batman} alt="batman"></img>
							</figure>
						</a>
					</st.MainImageContainer>
				</st.Columns>

				<SiteFooter />
			</st.FullHeightVerticalContainer>
		);
	}
}
