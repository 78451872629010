import * as React from 'react';
import selfportrait from '../../../img/drawings/self-portrait.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface SelfPortraitState {}

interface SelfPortraitProps {}

export default class SelfPortrait extends React.Component<
  SelfPortraitProps,
  SelfPortraitState
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <st.VerySmallPaddedSection />

        <st.Columns className="columns is-centered">
          <st.MainImageContainer className="column is-4">
            <div className="level">
              <a href="/drawings/sutcliffe">
                <figure className="image">
                  <img src={selfportrait} alt="self portrait"></img>
                </figure>
              </a>
            </div>
            <div className="level has-text-left">
              <div className="content is-size-7 has-text-centered-mobile">
                SELF PORTRAIT <br /> <i>2020</i> <br /> Graphite on Paper. 10.5
                x 7.25 in. <i> Available. </i>
              </div>
            </div>
          </st.MainImageContainer>
        </st.Columns>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/drawings/windows">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/drawings/sutcliffe">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/drawings1" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
