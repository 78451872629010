import * as React from 'react';
import Index from './layout/index';

import Portfolio from './layout/portfolio';

import Contact from './layout/contact';

import Drawings1 from './layout/drawings/index';
import Drawings2 from './layout/drawings/drawings2';
import Drawings3 from './layout/drawings/drawings3';
import Drawing1 from './layout/drawings/drawing1';
import Drawing2 from './layout/drawings/drawing2';
import TwoHeads from './layout/drawings/two-heads';
import ArmHead from './layout/drawings/arm-head';
import Drawing5 from './layout/drawings/drawing5';
import Drawing6 from './layout/drawings/drawing6';
import Icthyology from './layout/drawings/icthyology';
import Vase from './layout/drawings/vase';
import Guitar from './layout/drawings/guitar';
import Warriors from './layout/drawings/warriors';
import Drawing11 from './layout/drawings/drawing11';
import Leviathan from './layout/drawings/leviathan';
import BirdHead from './layout/drawings/bird-head';
import Preggo from './layout/drawings/preggo';
import Toothface from './layout/drawings/toothface';
import Organism from './layout/drawings/organism';
import Vaporhead from './layout/drawings/vaporhead';
import Batman from './layout/drawings/batman';
import Daydreams from './layout/drawings/daydreams';
import Drawing20 from './layout/drawings/drawing20';
import Earface from './layout/drawings/earface';
import Lampshade from './layout/drawings/lampshade';
import Detritus1 from './layout/drawings/detritus1';
import Detritus2 from './layout/drawings/detritus2';
import Eustace from './layout/drawings/eustace';
import GreenSun from './layout/drawings/green-sun';
import GreenCircle from './layout/drawings/green-circle';
import Putrefaction from './layout/drawings/putrefaction';
import Muybridge from './layout/drawings/muybridge';
import Predator from './layout/drawings/predator';
import Serpent from './layout/drawings/serpent';
import WhiteHorse from './layout/drawings/white-horse';
import YellowBox from './layout/drawings/yellow-box';
import Architecture from './layout/drawings/architecture';
import LiveOak from './layout/drawings/live-oak';
import OilRig from './layout/drawings/oil-rig';
import SleighBells from './layout/drawings/sleigh-bells';
import Windows from './layout/drawings/windows';
import SelfPortrait from './layout/drawings/self-portrait';
import Sutcliffe from './layout/drawings/sutcliffe';
import Homestar from './layout/drawings/homestar';

import Paintings from './layout/paintings/index';
import Faces from './layout/paintings/faces';
import Flowers1 from './layout/paintings/flowers1';
import Card2 from './layout/paintings/card2';
import Card1 from './layout/paintings/card1';
import PaynesGrey from './layout/paintings/paynesgrey';
import Harbor from './layout/paintings/harbor';
import Buggy from './layout/paintings/buggy';
import Boh from './layout/paintings/boh';

import Photographs1 from './layout/photographs/index';
import Photographs2 from './layout/photographs/photographs2';
import Photograph1 from './layout/photographs/photograph1';
import Photograph2 from './layout/photographs/photograph2';
import Photograph3 from './layout/photographs/photograph3';
import Photograph4 from './layout/photographs/photograph4';
import Photograph5 from './layout/photographs/photograph5';
import Photograph6 from './layout/photographs/photograph6';
import Photograph7 from './layout/photographs/photograph7';
import Photograph8 from './layout/photographs/photograph8';
import Photograph9 from './layout/photographs/photograph9';
import Photograph10 from './layout/photographs/photograph10';
import Photograph11 from './layout/photographs/photograph11';
import Photograph12 from './layout/photographs/photograph12';
import Photograph13 from './layout/photographs/photograph13';
import Photograph14 from './layout/photographs/photograph14';
import Photograph15 from './layout/photographs/photograph15';
import Photograph16 from './layout/photographs/photograph16';
import Photograph17 from './layout/photographs/photograph17';
import Photograph18 from './layout/photographs/photograph18';
import Photograph19 from './layout/photographs/photograph19';
import Photograph20 from './layout/photographs/photograph20';
import Photograph21 from './layout/photographs/photograph21';
import Photograph22 from './layout/photographs/photograph22';
import Photograph23 from './layout/photographs/photograph23';
import Photograph24 from './layout/photographs/photograph24';
import Photograph25 from './layout/photographs/photograph25';
import Photograph26 from './layout/photographs/photograph26';
import Photograph27 from './layout/photographs/photograph27';
import Photograph28 from './layout/photographs/photograph28';
import Photograph29 from './layout/photographs/photograph29';
import Photograph30 from './layout/photographs/photograph30';
import Photograph31 from './layout/photographs/photograph31';
import Photograph32 from './layout/photographs/photograph32';
import Photograph33 from './layout/photographs/photograph33';

import { BrowserRouter as Router, Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import Site from './layout/site';

interface EmptyProps {}

interface EmptyState {}

function IndexSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Index />
		</Site>
	);
}

function PortfolioSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Portfolio | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Portfolio />
		</Site>
	);
}

function Drawings1Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Drawings | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawings1 />
		</Site>
	);
}

function Drawings2Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Drawings | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawings2 />
		</Site>
	);
}

function Drawings3Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Drawings | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawings3 />
		</Site>
	);
}

function Drawing1Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Cybernetic Organism | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawing1 />
		</Site>
	);
}

function Drawing2Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Detail of a Merry-Go-Round | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawing2 />
		</Site>
	);
}

function TwoHeadsSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Head Growing out of a Head | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<TwoHeads />
		</Site>
	);
}

function ArmHeadSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Overhead | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<ArmHead />
		</Site>
	);
}

function Drawing5Site(props: any) {
	return (
		<Site>
			<Helmet
				title="The Torment of Saint Birdman | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawing5 />
		</Site>
	);
}

function Drawing6Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Hummingbird with Projected Eyes | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawing6 />
		</Site>
	);
}

function IcthyologySite(props: any) {
	return (
		<Site>
			<Helmet
				title="Icthyology | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Icthyology />
		</Site>
	);
}

function VaseSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Partial Vase | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Vase />
		</Site>
	);
}

function GuitarSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Sowmya with Guitar | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Guitar />
		</Site>
	);
}

function WarriorsSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Three Warriors on a Battlefield | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Warriors />
		</Site>
	);
}

function Drawing11Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Study for a Portrait | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawing11 />
		</Site>
	);
}

function LeviathanSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Leviathan | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Leviathan />
		</Site>
	);
}

function BirdHeadSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Bird Emerging from Human Head | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<BirdHead />
		</Site>
	);
}

function PreggoSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Pregnant Life-Form | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Preggo />
		</Site>
	);
}

function ToothfaceSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Figure with Mouths Instead of Eyes | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Toothface />
		</Site>
	);
}

function OrganismSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Cluster of Organic Forms | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Organism />
		</Site>
	);
}

function VaporheadSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Vaporhead | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Vaporhead />
		</Site>
	);
}

function BatmanSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Exploding Figure with Wings | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Batman />
		</Site>
	);
}

function DaydreamsSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Daydreams | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Daydreams />
		</Site>
	);
}

function Drawing20Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Faces With Geometric Forms | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Drawing20 />
		</Site>
	);
}

function EarfaceSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Figure with Ears Instead of Eyes | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Earface />
		</Site>
	);
}

function LampshadeSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Still Life with Lampshade | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Lampshade />
		</Site>
	);
}

function Detritus1Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Detritus 1 | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Detritus1 />
		</Site>
	);
}

function Detritus2Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Detritus 2 | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Detritus2 />
		</Site>
	);
}

function EustaceSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Study for a Portrait of Eustace Bagge | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Eustace />
		</Site>
	);
}

function GreenSunSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Woman with Chartreuse Sun | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<GreenSun />
		</Site>
	);
}

function GreenCircleSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Man with Chartreuse Circle | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<GreenCircle />
		</Site>
	);
}

function PutrefactionSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Putrefaction | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Putrefaction />
		</Site>
	);
}

function MuybridgeSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Child with Red Sun | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Muybridge />
		</Site>
	);
}

function PredatorSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Predator with Partially Eaten Carcass | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Predator />
		</Site>
	);
}

function SerpentSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Il Serpente | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Serpent />
		</Site>
	);
}

function WhiteHorseSite(props: any) {
	return (
		<Site>
			<Helmet
				title="White Horse | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<WhiteHorse />
		</Site>
	);
}

function YellowBoxSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Still Life with Yellow Rectangles | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<YellowBox />
		</Site>
	);
}

function ArchitectureSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Rectangular Forms | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Architecture />
		</Site>
	);
}

function LiveOakSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Scene with Live Oak Branches | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<LiveOak />
		</Site>
	);
}

function OilRigSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Oil Rig and Lamp | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<OilRig />
		</Site>
	);
}

function SleighBellsSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Sleigh Bells | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<SleighBells />
		</Site>
	);
}

function WindowsSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Interior with Telephone Lines | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Windows />
		</Site>
	);
}

function SelfPortraitSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Self Portrait | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<SelfPortrait />
		</Site>
	);
}

function SutcliffeSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Cassette Box | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Sutcliffe />
		</Site>
	);
}

function HomestarSite(props: any) {
	return (
		<Site>
			<Helmet
				title="The Ascension of Homestar Runner | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content:
							'art, watercolor, ink, drawing, photography, painting, oil',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Homestar />
		</Site>
	);
}

function PaintingsSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Paintings | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Paintings />
		</Site>
	);
}

function FacesSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Faces | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Faces />
		</Site>
	);
}

function Flowers1Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Orange Flowers with Blue Triangle | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Flowers1 />
		</Site>
	);
}

function Card2Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Los Angeles Landscape 2 | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Card2 />
		</Site>
	);
}

function Card1Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Los Angeles Landscape 1 | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Card1 />
		</Site>
	);
}

function PaynesGreySite(props: any) {
	return (
		<Site>
			<Helmet
				title="Monochrome in Payne's Grey | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<PaynesGrey />
		</Site>
	);
}

function HarborSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Harbor | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Harbor />
		</Site>
	);
}

function BuggySite(props: any) {
	return (
		<Site>
			<Helmet
				title="Chihuahua in Repose"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Buggy />
		</Site>
	);
}

function BohSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper with Stick"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Boh />
		</Site>
	);
}

function Photographs1Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Photographs | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photographs1 />
		</Site>
	);
}

function Photographs2Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Photographs | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photographs2 />
		</Site>
	);
}

function Photograph1Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Summit Block of Mt. Shasta | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph1 />
		</Site>
	);
}

function Photograph2Site(props: any) {
	return (
		<Site>
			<Helmet
				title="South Face of Mt. Shasta | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph2 />
		</Site>
	);
}

function Photograph3Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper in Tilden | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph3 />
		</Site>
	);
}

function Photograph4Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper at Sanborn-Skyline | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph4 />
		</Site>
	);
}

function Photograph5Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Sowmya at Sanborn-Skyline | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph5 />
		</Site>
	);
}

function Photograph6Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper Defending Her Territory | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph6 />
		</Site>
	);
}

function Photograph7Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper and Sowmya | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph7 />
		</Site>
	);
}

function Photograph8Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper on the Bed | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph8 />
		</Site>
	);
}

function Photograph9Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Sowmya with Brass Lamp | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph9 />
		</Site>
	);
}

function Photograph10Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Tree at Mammoth Lakes | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph10 />
		</Site>
	);
}

function Photograph11Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper at Point Reyes | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph11 />
		</Site>
	);
}

function Photograph12Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Sowmya at Point Reyes | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph12 />
		</Site>
	);
}

function Photograph13Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Tree at Pace Bend | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph13 />
		</Site>
	);
}

function Photograph14Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Backyard | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph14 />
		</Site>
	);
}

function Photograph15Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Sowmya by the Window | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph15 />
		</Site>
	);
}

function Photograph16Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Cropped Trees on Henry Street | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph16 />
		</Site>
	);
}

function Photograph17Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Trees on Henry Street | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph17 />
		</Site>
	);
}

function Photograph18Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper at Live Oak Park | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph18 />
		</Site>
	);
}

function Photograph19Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Sowmya at Live Oak Park | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph19 />
		</Site>
	);
}

function Photograph20Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper in Turquoise Chair | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph20 />
		</Site>
	);
}

function Photograph21Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper at Indian Rock 2 | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph21 />
		</Site>
	);
}

function Photograph22Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper at Indian Rock 1 | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph22 />
		</Site>
	);
}

function Photograph23Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Granite Slab in Yosemite Valley | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph23 />
		</Site>
	);
}

function Photograph24Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Above Yosemite Falls | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph24 />
		</Site>
	);
}

function Photograph25Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Yosemite Valley | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph25 />
		</Site>
	);
}

function Photograph26Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Piper at Goat Rock | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph26 />
		</Site>
	);
}

function Photograph27Site(props: any) {
	return (
		<Site>
			<Helmet
				title="View from West Ridge of Castle Peak | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph27 />
		</Site>
	);
}

function Photograph28Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Cow at Briones | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph28 />
		</Site>
	);
}

function Photograph29Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Sunset at Briones | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph29 />
		</Site>
	);
}

function Photograph30Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Sowmya | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph30 />
		</Site>
	);
}

function Photograph31Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Eastern Sierra Nevada in Clouds | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph31 />
		</Site>
	);
}

function Photograph32Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Buttermilk Boulders | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph32 />
		</Site>
	);
}

function Photograph33Site(props: any) {
	return (
		<Site>
			<Helmet
				title="Eastern Sierra Nevada | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Photograph33 />
		</Site>
	);
}

function ContactSite(props: any) {
	return (
		<Site>
			<Helmet
				title="Contact | Korok Chatterjee"
				meta={[
					{
						name: 'description',
						content: 'Art and art-related stuff by Korok Chatterjee.',
					},
					{
						name: 'keywords',
						content: 'art, watercolor, ink, drawing, illustration',
					},
				]}
				link={[
					{
						rel: 'stylesheet',
						href: 'https://use.fontawesome.com/releases/v5.8.2/css/all.css',
					},
				]}
			/>
			<Contact />
		</Site>
	);
}

export default class Layout extends React.Component<EmptyProps, EmptyState> {
	render() {
		return (
			<Router>
				<Route exact path="/" render={(props) => IndexSite(props)} />
				<Route
					exact
					path="/portfolio"
					render={(props) => PortfolioSite(props)}
				/>
				<Route exact path="/contact" render={(props) => ContactSite(props)} />
				<Route
					exact
					path="/drawings1"
					render={(props) => Drawings1Site(props)}
				/>
				<Route
					exact
					path="/drawings2"
					render={(props) => Drawings2Site(props)}
				/>
				<Route
					exact
					path="/drawings3"
					render={(props) => Drawings3Site(props)}
				/>
				<Route
					exact
					path="/drawings/drawing1"
					render={(props) => Drawing1Site(props)}
				/>
				<Route
					exact
					path="/drawings/drawing2"
					render={(props) => Drawing2Site(props)}
				/>
				<Route
					exact
					path="/drawings/twoheads"
					render={(props) => TwoHeadsSite(props)}
				/>
				<Route
					exact
					path="/drawings/armhead"
					render={(props) => ArmHeadSite(props)}
				/>
				<Route
					exact
					path="/drawings/drawing5"
					render={(props) => Drawing5Site(props)}
				/>
				<Route
					exact
					path="/drawings/drawing6"
					render={(props) => Drawing6Site(props)}
				/>
				<Route
					exact
					path="/drawings/icthyology"
					render={(props) => IcthyologySite(props)}
				/>
				<Route
					exact
					path="/drawings/vase"
					render={(props) => VaseSite(props)}
				/>
				<Route
					exact
					path="/drawings/guitar"
					render={(props) => GuitarSite(props)}
				/>
				<Route
					exact
					path="/drawings/warriors"
					render={(props) => WarriorsSite(props)}
				/>
				<Route
					exact
					path="/drawings/drawing11"
					render={(props) => Drawing11Site(props)}
				/>
				<Route
					exact
					path="/drawings/leviathan"
					render={(props) => LeviathanSite(props)}
				/>
				<Route
					exact
					path="/drawings/birdhead"
					render={(props) => BirdHeadSite(props)}
				/>
				<Route
					exact
					path="/drawings/preggo"
					render={(props) => PreggoSite(props)}
				/>
				<Route
					exact
					path="/drawings/toothface"
					render={(props) => ToothfaceSite(props)}
				/>
				<Route
					exact
					path="/drawings/organism"
					render={(props) => OrganismSite(props)}
				/>
				<Route
					exact
					path="/drawings/vaporhead"
					render={(props) => VaporheadSite(props)}
				/>
				<Route
					exact
					path="/drawings/batman"
					render={(props) => BatmanSite(props)}
				/>
				<Route
					exact
					path="/drawings/daydreams"
					render={(props) => DaydreamsSite(props)}
				/>
				<Route
					exact
					path="/drawings/drawing20"
					render={(props) => Drawing20Site(props)}
				/>
				<Route
					exact
					path="/drawings/earface"
					render={(props) => EarfaceSite(props)}
				/>
				<Route
					exact
					path="/drawings/lampshade"
					render={(props) => LampshadeSite(props)}
				/>
				<Route
					exact
					path="/drawings/detritus1"
					render={(props) => Detritus1Site(props)}
				/>
				<Route
					exact
					path="/drawings/detritus2"
					render={(props) => Detritus2Site(props)}
				/>
				<Route
					exact
					path="/drawings/eustace"
					render={(props) => EustaceSite(props)}
				/>
				<Route
					exact
					path="/drawings/greensun"
					render={(props) => GreenSunSite(props)}
				/>
				<Route
					exact
					path="/drawings/greencircle"
					render={(props) => GreenCircleSite(props)}
				/>
				<Route
					exact
					path="/drawings/putrefaction"
					render={(props) => PutrefactionSite(props)}
				/>
				<Route
					exact
					path="/drawings/muybridge"
					render={(props) => MuybridgeSite(props)}
				/>
				<Route
					exact
					path="/drawings/predator"
					render={(props) => PredatorSite(props)}
				/>
				<Route
					exact
					path="/drawings/serpent"
					render={(props) => SerpentSite(props)}
				/>
				<Route
					exact
					path="/drawings/whitehorse"
					render={(props) => WhiteHorseSite(props)}
				/>
				<Route
					exact
					path="/drawings/yellowbox"
					render={(props) => YellowBoxSite(props)}
				/>
				<Route
					exact
					path="/drawings/architecture"
					render={(props) => ArchitectureSite(props)}
				/>
				<Route
					exact
					path="/drawings/liveoak"
					render={(props) => LiveOakSite(props)}
				/>
				<Route
					exact
					path="/drawings/oilrig"
					render={(props) => OilRigSite(props)}
				/>
				<Route
					exact
					path="/drawings/sleighbells"
					render={(props) => SleighBellsSite(props)}
				/>
				<Route
					exact
					path="/drawings/windows"
					render={(props) => WindowsSite(props)}
				/>
				<Route
					exact
					path="/drawings/selfportrait"
					render={(props) => SelfPortraitSite(props)}
				/>
				<Route
					exact
					path="/drawings/sutcliffe"
					render={(props) => SutcliffeSite(props)}
				/>
				<Route
					exact
					path="/drawings/homestar"
					render={(props) => HomestarSite(props)}
				/>
				<Route
					exact
					path="/paintings"
					render={(props) => PaintingsSite(props)}
				/>
				<Route
					exact
					path="/paintings/faces"
					render={(props) => FacesSite(props)}
				/>
				<Route
					exact
					path="/paintings/flowers1"
					render={(props) => Flowers1Site(props)}
				/>
				<Route
					exact
					path="/paintings/card2"
					render={(props) => Card2Site(props)}
				/>
				<Route
					exact
					path="/paintings/card1"
					render={(props) => Card1Site(props)}
				/>
				<Route
					exact
					path="/paintings/paynesgrey"
					render={(props) => PaynesGreySite(props)}
				/>
				<Route
					exact
					path="/paintings/harbor"
					render={(props) => HarborSite(props)}
				/>
				<Route
					exact
					path="/paintings/buggy"
					render={(props) => BuggySite(props)}
				/>
				<Route exact path="/paintings/boh" render={(props) => BohSite(props)} />
				<Route
					exact
					path="/photographs1"
					render={(props) => Photographs1Site(props)}
				/>
				<Route
					exact
					path="/photographs2"
					render={(props) => Photographs2Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph1"
					render={(props) => Photograph1Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph2"
					render={(props) => Photograph2Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph3"
					render={(props) => Photograph3Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph4"
					render={(props) => Photograph4Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph5"
					render={(props) => Photograph5Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph6"
					render={(props) => Photograph6Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph7"
					render={(props) => Photograph7Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph8"
					render={(props) => Photograph8Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph9"
					render={(props) => Photograph9Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph10"
					render={(props) => Photograph10Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph11"
					render={(props) => Photograph11Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph12"
					render={(props) => Photograph12Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph13"
					render={(props) => Photograph13Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph14"
					render={(props) => Photograph14Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph15"
					render={(props) => Photograph15Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph16"
					render={(props) => Photograph16Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph17"
					render={(props) => Photograph17Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph18"
					render={(props) => Photograph18Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph19"
					render={(props) => Photograph19Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph20"
					render={(props) => Photograph20Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph21"
					render={(props) => Photograph21Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph22"
					render={(props) => Photograph22Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph23"
					render={(props) => Photograph23Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph24"
					render={(props) => Photograph24Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph25"
					render={(props) => Photograph25Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph26"
					render={(props) => Photograph26Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph27"
					render={(props) => Photograph27Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph28"
					render={(props) => Photograph28Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph29"
					render={(props) => Photograph29Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph30"
					render={(props) => Photograph30Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph31"
					render={(props) => Photograph31Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph32"
					render={(props) => Photograph32Site(props)}
				/>
				<Route
					exact
					path="/photographs/photograph33"
					render={(props) => Photograph33Site(props)}
				/>
			</Router>
		);
	}
}
