import * as React from 'react';

import biper from '../../img/photographs/biper-crop.jpg';
import vaporhead from '../../img/drawings/vaporhead-crop.jpg';
import card1 from '../../img/paintings/card1-crop.jpg';
import sowmz from '../../img/drawings/sowmz-new-crop.jpg';
import earface from '../../img/drawings/earface-new-crop.jpg';

import * as st from '../../components/styled-component-definitions';
import SiteFooter from './sitefooter';
import SiteHeader from './siteheader';

interface PortfolioState {}

interface PortfolioProps {}

export default class Portfolio extends React.Component<
	PortfolioProps,
	PortfolioState
> {
	render() {
		return (
			<st.FullHeightVerticalContainer>
				<SiteHeader />

				<div className="columns is-mobile">
					<div className="column is-centered has-text-centered is-size-6">
						ARRANGED BY MEDIUM
					</div>
				</div>

				<div className="container">
					<div className="columns is-centered">
						<div className="column is-one-third">
							<a href="/drawings1">
								<figure className="image-holder wp-caption">
									<img className="demo" src={earface} alt="Drawings"></img>
									<st.VerySmallPaddedSection />
									<figcaption className="wp-caption-text has-text-centered">
										DRAWING
									</figcaption>
								</figure>
							</a>
						</div>
						<div className="column is-one-third">
							<a href="/paintings">
								<figure className="image-holder wp-caption">
									<img className="demo" src={card1} alt="Paintings"></img>
									<st.VerySmallPaddedSection />
									<figcaption className="wp-caption-text has-text-centered">
										PAINTING
									</figcaption>
								</figure>
							</a>
						</div>
						<div className="column is-one-third">
							<a href="/photographs1">
								<figure className="image-holder wp-caption">
									<img className="demo" src={biper} alt="Photographs" />
									<st.VerySmallPaddedSection />
									<figcaption className="wp-caption-text has-text-centered">
										PHOTOGRAPHY
									</figcaption>
								</figure>
							</a>
						</div>
					</div>
				</div>

				<st.PaddedSection />

				<SiteFooter />
			</st.FullHeightVerticalContainer>
		);
	}
}
