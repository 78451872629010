import * as React from 'react';
import valley from '../../../img/photographs/valley-crop.jpg';
import yosemite from '../../../img/photographs/yosemite-crop.jpg';
import eastside from '../../../img/photographs/eastside-crop.jpg';
import buttermilks from '../../../img/photographs/buttermilks-crop.jpg';
import castlepeak from '../../../img/photographs/castlepeak-crop.jpg';
import biper from '../../../img/photographs/biper-crop.jpg';
import sowmz from '../../../img/photographs/sowmz-crop.jpg';
import briones from '../../../img/photographs/briones-crop.jpg';
import brionescow from '../../../img/photographs/briones-cow-crop.jpg';
import cropped from '../../../img/photographs/cropped-crop.jpg';
import mountainfog from '../../../img/photographs/mountain-fog-crop.jpg';
import piperbeach from '../../../img/photographs/piper-beach-crop.jpg';
import piperbed from '../../../img/photographs/piper-bed-crop.jpg';
import pipercastlerock from '../../../img/photographs/piper-castlerock-crop.jpg';
import piperchair from '../../../img/photographs/piper-chair-crop.jpg';
import piperfence from '../../../img/photographs/piper-fence-crop.jpg';
import pipergoat from '../../../img/photographs/piper-goat-crop.jpg';
import pipergrass from '../../../img/photographs/piper-grass-crop.jpg';
import piperindian from '../../../img/photographs/piper-indian-crop.jpg';
import pipertilden from '../../../img/photographs/piper-tilden-crop.jpg';
import shastasouthface from '../../../img/photographs/shastasouthface-crop.jpg';
import shastasummit from '../../../img/photographs/shastasummit-crop.jpg';
import sowmzbeach from '../../../img/photographs/sowmz-beach-crop.jpg';
import sowmzlamp from '../../../img/photographs/sowmz-lamp-crop.jpg';
import sowmzlop from '../../../img/photographs/sowmz-lop-crop.jpg';
import sowmzcastlerock from '../../../img/photographs/sowmz-castlerock-crop.jpg';
import sowmzwindow from '../../../img/photographs/sowmz-window-crop.jpg';
import treeaustin from '../../../img/photographs/tree-austin-crop.jpg';
import treemammoth from '../../../img/photographs/tree-mammoth-crop.jpg';
import treeshenry from '../../../img/photographs/trees-henry-crop.jpg';
import yard from '../../../img/photographs/yard-crop.jpg';
import yosemiteslab from '../../../img/photographs/yosemite-slab-crop.jpg';

import * as st from '../../../components/styled-component-definitions';
import SiteFooter from '../sitefooter';
import SiteHeader from '../siteheader';

interface Photographs1State {}

interface Photographs1Props {}

export default class Photographs1 extends React.Component<
  Photographs1Props,
  Photographs1State
> {
  render() {
    return (
      <st.FullHeightVerticalContainer>
        <SiteHeader />

        <div className="columns is-mobile">
          <st.AltContent className="column is-centered has-text-centered">
            PHOTOGRAPHS
          </st.AltContent>
        </div>

        <div className="columns is-mobile is-hidden-desktop">
          <st.AltContent className="column is-centered has-text-centered is-size-7">
            tap image to view full size
          </st.AltContent>
        </div>

        <div className="container">
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph33">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={eastside}
                    alt="eastern sierra nevada"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph32">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={buttermilks}
                    alt="buttermilk boulders"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph31">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={mountainfog}
                    alt="eastern sierra nevada in clouds"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph30">
                <figure className="image-holder wp-caption">
                  <img className="demo" src={sowmz} alt="sowmya"></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph29">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={briones}
                    alt="cow at briones"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph28">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={brionescow}
                    alt="sunset at briones"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph27">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={castlepeak}
                    alt="view from the west ridge of castle peak"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph26">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={pipergoat}
                    alt="piper at goat rock"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph25">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={valley}
                    alt="yosemite valley"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph24">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={yosemite}
                    alt="above yosemite falls"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph23">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={yosemiteslab}
                    alt="granite slab in yosemite valley"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph22">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={biper}
                    alt="piper at indian rock"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column is-one-quarter">
              <a href="photographs/photograph21">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={piperindian}
                    alt="nervous piper"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph20">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={piperchair}
                    alt="piper in turquoise chair"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph19">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={sowmzlop}
                    alt="sowmya at live oak park"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
            <div className="column is-one-quarter">
              <a href="photographs/photograph18">
                <figure className="image-holder wp-caption">
                  <img
                    className="demo"
                    src={pipergrass}
                    alt="piper at live oak park"
                  ></img>
                  <st.VerySmallPaddedSection />
                </figure>
              </a>
            </div>
          </div>
        </div>

        <st.SmallPaddedSection />

        <div className="section has-text-centered">
          <p>
            <a href="/photographs2">
              <i
                className="fa fa-chevron-left fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
            &emsp; &emsp; &emsp;
            <a href="/photographs2">
              <i
                className="fa fa-chevron-right fa-lg darkgrayiconcolor"
                aria-hidden="true"
              ></i>
            </a>{' '}
          </p>
        </div>

        <div className="no-margin is-centered has-text-centered is-size-7">
          <a href="/portfolio" className="darkgrayiconcolor">
            {' '}
            <b>RETURN </b>
          </a>
        </div>

        <SiteFooter />
      </st.FullHeightVerticalContainer>
    );
  }
}
