//import PropTypes from 'prop-types';
import * as React from 'react';
import styled from 'styled-components';
//import ua from 'universal-analytics';
//import { BaseProps, EmptyState } from '../../lib/interfaces';

const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
`;

export interface EmptyState {}
export interface ChildrenProps {
  children?: any;
}
export type BaseProps = ChildrenProps;

export default class Site extends React.Component<BaseProps, EmptyState> {
  /*static get propTypes() {
    return {
      visitor: PropTypes.instanceOf(ua.Visitor).isRequired,
      children: PropTypes.any
    };
  }
  */

  render() {
    return <ContainerDiv>{this.props.children}</ContainerDiv>;
  }
}
